import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import clipboardCopy from "clipboard-copy";

function Menu() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const csvLink = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const headers = [
    { label: "S No.", key: "sNo" },
    { label: "Menu Name", key: "menuName" },
    { label: "Admin Service", key: "adminService" },
    { label: "Menu City", key: "menuCity" },
    { label: "Action", key: "action" },
  ];

  const [formData, setFormData] = useState({
    sNo: "",
    menuName: "",
    adminService: "",
    menuCity: "",
    action: "N/A",
    flowType: "transport",
    menuType: "",
    color: "#000000",
    sortOrder: 1,
    icon: null,
  });

  useEffect(() => {
    const defaultData = [];
    setData(defaultData);
    setIsLoading(false);
  }, []);

  const handleModalToggle = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(data.concat(formData));
    setFormData({
      sNo: "",
      menuName: "",
      adminService: "",
      menuCity: "",
      action: "N/A",
      flowType: "transport",
      menuType: "",
      color: "#000000",
      sortOrder: 1,
      icon: null,
    });
    handleModalToggle();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table-data.xlsx");
  };

  const exportToPDF = () => {
    const input = document.getElementById("export-table");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("table-data.pdf");
    });
  };

  const copyToClipboard = () => {
    const tableText = data
      .map((row) => headers.map((header) => row[header.key]).join("\t"))
      .join("\n");
    clipboardCopy(tableText);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentss">
        <div className="user1">
          <div className="row">
            <div className="ltopic col-6">Menu</div>
            <div className="rtopic col-6">
              <Button variant="info" onClick={handleModalToggle}>
                + Add New Menu
              </Button>
            </div>
          </div>
        </div>

        <div className="user2">
          <div className="row mt-1 mb-2">
            <div className="kkk col-6">
              <div>
                <Button variant="secondary" onClick={copyToClipboard}>
                  Copy
                </Button>
                <Button variant="info ms-2">
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename="table-data.csv"
                    className="btnsss"
                    ref={csvLink}
                    target="_blank"
                  >
                    CSV
                  </CSVLink>
                </Button>
                <Button variant="success ms-2" onClick={exportToExcel}>
                  Excel
                </Button>
                <Button variant="danger ms-2" onClick={exportToPDF}>
                  PDF
                </Button>
              </div>
            </div>
            <div className="kkkk col-6 d-flex">
              <h1>Search:</h1>
              <Form.Control
                className="searchh"
                type="text"
                placeholder=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Container>
          <Modal show={showModal} onHide={handleModalToggle}>
            <Modal.Header closeButton>
              <Modal.Title>Add Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">
              <Form onSubmit={handleSubmit} className="mb-3 mt-1 ">
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Menu Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="menuName"
                        value={formData.menuName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Admin Service</Form.Label>
                      <Form.Control
                        type="text"
                        name="adminService"
                        value={formData.adminService}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Menu City</Form.Label>
                      <Form.Control
                        type="text"
                        name="menuCity"
                        value={formData.menuCity}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Flow Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="flowType"
                        value={formData.flowType}
                        onChange={handleChange}
                        required
                      >
                        <option value="transport">Transport</option>
                        <option value="order">Order</option>
                        <option value="service">Service</option>
                        <option value="delivery">Delivery</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Menu Type</Form.Label>
                      <Form.Control
                        type="text"
                        name="menuType"
                        value={formData.menuType}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Color</Form.Label>
                      <Form.Control
                        type="color"
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Sort Order</Form.Label>
                      <Form.Control
                        type="number"
                        name="sortOrder"
                        value={formData.sortOrder}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Icon</Form.Label>
                      <Form.Control
                        type="file"
                        name="icon"
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Col md={12} className="d-flex align-items-end mt-3">
                  <Button type="submit" variant="success">
                    Add Menu
                  </Button>
                  <Button variant="danger ms-4" onClick={handleModalToggle}>
                    Cancel
                  </Button>
                </Col>
              </Form>
            </Modal.Body>
          </Modal>

          <Table striped bordered hover responsive id="export-table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  {headers.map((header) => (
                    <td key={header.key}>{item[header.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        <div className="pagination">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="btopic1">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                {filteredData.length} entries
              </h1>
            </div>
            <div className="col-lg-6 mover">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  variant={
                    index + 1 === currentPage
                      ? "secondary"
                      : "outline-secondary"
                  }
                  className="me-2"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                variant="outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
