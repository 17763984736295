import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import clipboardCopy from "clipboard-copy";

function Shop() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const csvLink = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const headers = [
    { label: "S No.", key: "sNo" },
    { label: "Shop Type", key: "shopType" },
    { label: "Shop Name", key: "shopName" },
    { label: "Email", key: "email" },
    { label: "Address", key: "location" },
    { label: "Contact No.", key: "contactNo" },
    { label: "Status", key: "status" },
    { label: "Action", key: "action" },
  ];

  const [formData, setFormData] = useState({
    sNo: "",
    shopType: "",
    shopName: "",
    email: "",
    minAmount: "",
    estimatedDeliveryTime: "",
    offerPercentage: "",
    vegNonVeg: "pure veg",
    cuisineName: "",
    gstTaxPercentage: "",
    commissionPercentage: "",
    countryName: "usa",
    cityName: "",
    contactPerson: "",
    description: "",
    contactNo: "",
    location: "",
    password: "",
    zipcode: "",
    confirmPassword: "",
    packingCharges: "",
    status: "active",
    zone: "",
    picture: null,
    shopResponseTime: "",
    bestSellerCount: "",
    shopTimingType: "shop open timing",
    shopOpens: "",
    shopCloses: "",
    action: "N/A",
  });

  useEffect(() => {
    const defaultData = [];
    setData(defaultData);
    setIsLoading(false);
  }, []);

  const handleModalToggle = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(data.concat(formData));
    setFormData({
      sNo: "",
      shopType: "",
      shopName: "",
      email: "",
      minAmount: "",
      estimatedDeliveryTime: "",
      offerPercentage: "",
      vegNonVeg: "pure veg",
      cuisineName: "",
      gstTaxPercentage: "",
      commissionPercentage: "",
      countryName: "usa",
      cityName: "",
      contactPerson: "",
      description: "",
      contactNo: "",
      location: "",
      password: "",
      zipcode: "",
      confirmPassword: "",
      packingCharges: "",
      status: "active",
      zone: "",
      picture: null,
      shopResponseTime: "",
      bestSellerCount: "",
      shopTimingType: "shop open timing",
      shopOpens: "",
      shopCloses: "",
      action: "N/A",
    });
    handleModalToggle();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table-data.xlsx");
  };

  const exportToPDF = () => {
    const input = document.getElementById("export-table");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("table-data.pdf");
    });
  };

  const copyToClipboard = () => {
    const tableText = data
      .map((row) => headers.map((header) => row[header.key]).join("\t"))
      .join("\n");
    clipboardCopy(tableText);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentss">
        <div className="user1">
          <div className="row">
            <div className="ltopic col-6">Shops</div>
            <div className="rtopic col-6">
              <Button variant="info" onClick={handleModalToggle}>
                + Add Shops
              </Button>
            </div>
          </div>
        </div>

        <div className="user2">
          <div className="row mt-1 mb-2">
            <div className="kkk col-6">
              <div>
                <Button variant="secondary" onClick={copyToClipboard}>
                  Copy
                </Button>
                <Button variant="info ms-2">
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename="table-data.csv"
                    className="btnsss"
                    ref={csvLink}
                    target="_blank"
                  >
                    CSV
                  </CSVLink>
                </Button>
                <Button variant="success ms-2" onClick={exportToExcel}>
                  Excel
                </Button>
                <Button variant="danger ms-2" onClick={exportToPDF}>
                  PDF
                </Button>
              </div>
            </div>
            <div className="kkkk col-6 d-flex">
              <h1>Search:</h1>
              <Form.Control
                className="searchh"
                type="text"
                placeholder=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Container>
          <Modal show={showModal} onHide={handleModalToggle}>
            <Modal.Header closeButton>
              <Modal.Title>Add Shops</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">
              <Form onSubmit={handleSubmit} className="mb-3 mt-1 ">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mt-3">
                      <Form.Label>S No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="sNo"
                        value={formData.sNo}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="shopType"
                        value={formData.shopType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Shop Type</option>
                        <option value="foodie">Foodie</option>
                        <option value="grocery">Grocery</option>
                        <option value="alcohol">Alcohol</option>
                        <option value="flower">Flower</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="shopName"
                        value={formData.shopName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Min Amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="minAmount"
                        value={formData.minAmount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Estimated Delivery Time (minutes)</Form.Label>
                      <Form.Control
                        type="number"
                        name="estimatedDeliveryTime"
                        value={formData.estimatedDeliveryTime}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Offer Percentage (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="offerPercentage"
                        value={formData.offerPercentage}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Pure Veg or Non-Veg</Form.Label>
                      <div>
                        <Form.Check
                          type="radio"
                          label="Pure Veg"
                          name="vegNonVeg"
                          value="pure veg"
                          checked={formData.vegNonVeg === "pure veg"}
                          onChange={handleChange}
                          required
                        />
                        <Form.Check
                          type="radio"
                          label="Non-Veg"
                          name="vegNonVeg"
                          value="non-veg"
                          checked={formData.vegNonVeg === "non-veg"}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Cuisine Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="cuisineName"
                        value={formData.cuisineName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>GST/Tax Percentage</Form.Label>
                      <Form.Control
                        type="number"
                        name="gstTaxPercentage"
                        value={formData.gstTaxPercentage}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mt-3">
                      <Form.Label>Commission Percentage (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="commissionPercentage"
                        value={formData.commissionPercentage}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Country Name</Form.Label>
                      <Form.Control
                        as="select"
                        name="countryName"
                        value={formData.countryName}
                        onChange={handleChange}
                        required
                      >
                        <option value="usa">USA</option>
                        <option value="nepal">Nepal</option>
                        <option value="vietnam">Vietnam</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>City Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="cityName"
                        value={formData.cityName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactPerson"
                        value={formData.contactPerson}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Contact No</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactNo"
                        value={formData.contactNo}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Zipcode</Form.Label>
                      <Form.Control
                        type="text"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Packing Charges ($)</Form.Label>
                      <Form.Control
                        type="number"
                        name="packingCharges"
                        value={formData.packingCharges}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Zone</Form.Label>
                      <Form.Control
                        type="text"
                        name="zone"
                        value={formData.zone}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Picture Upload</Form.Label>
                      <Form.Control
                        type="file"
                        name="picture"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Response Time</Form.Label>
                      <Form.Control
                        type="number"
                        name="shopResponseTime"
                        value={formData.shopResponseTime}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Best Seller Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="bestSellerCount"
                        value={formData.bestSellerCount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Timing Type</Form.Label>
                      <div>
                        <Form.Check
                          type="radio"
                          label="Shop Open Timing"
                          name="shopTimingType"
                          value="shop open timing"
                          checked={
                            formData.shopTimingType === "shop open timing"
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Check
                          type="radio"
                          label="Everyday"
                          name="shopTimingType"
                          value="everyday"
                          checked={formData.shopTimingType === "everyday"}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Opens</Form.Label>
                      <Form.Control
                        type="time"
                        name="shopOpens"
                        value={formData.shopOpens}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Shop Closes</Form.Label>
                      <Form.Control
                        type="time"
                        name="shopCloses"
                        value={formData.shopCloses}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="mt-3">
                  Add Shop
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleModalToggle}
                  className="mt-3 ms-2"
                >
                  Cancel
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>

        <Table striped bordered hover responsive className="mt-3">
          <thead>
            <tr>
              <th>S No.</th>
              <th>Shop Type</th>
              <th>Shop Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Contact No.</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="export-table">
            {currentData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                <td>{item.shopType}</td>
                <td>{item.shopName}</td>
                <td>{item.email}</td>
                <td>{item.location}</td>
                <td>{item.contactNo}</td>
                <td>{item.status}</td>
                <td>{item.action}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="pagination">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="btopic1">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                {filteredData.length} entries
              </h1>
            </div>
            <div className="col-lg-6 mover">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  variant={
                    index + 1 === currentPage
                      ? "secondary"
                      : "outline-secondary"
                  }
                  className="me-2"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                variant="outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
