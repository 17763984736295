import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { countries } from "countries-list";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const countryOptions = Object.keys(countries).map((code) => ({
  value: code,
  label: countries[code].name,
}));

export default function Customs() {
  const [value, setValue] = useState("1");
  const [file, setFile] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [editorData, setEditorData] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileSubmit = (event) => {
    event.preventDefault();
    if (file) {
      console.log("Selected file:", file.name);
    } else {
      console.log("No file selected.");
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleCountrySubmit = (event) => {
    event.preventDefault();
    if (selectedCountry) {
      console.log("Selected country:", selectedCountry.label);
    } else {
      console.log("No country selected.");
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    console.log({ event, editor, data });
  };

  return (
    <div className="right-content">
      <div className="site">
        <div className="container-fluid">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="CMS Pages" value="1" sx={{ fontSize: "12px" }} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="geeneral">
                  <div className="container-fluid">
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group className="dform mt-2">
                            <Form.Label>Page Name</Form.Label>
                            <Form.Select>
                              <option>Help</option>
                              <option>Privacy Policy</option>
                              <option>Cancelation Policy</option>
                              <option>About us</option>
                              <option>Legal</option>
                              <option>Faq</option>
                            </Form.Select>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className="row">
                      <div className="word col-12 mt-3">
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorData}
                          onChange={handleEditorChange}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-6">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group className="dform mt-2">
                            <Form.Label>Status</Form.Label>
                            <Form.Select>
                              <option>Active</option>
                              <option>Inactive</option>
                            </Form.Select>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12"></div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-6">
                        <Button>Submit</Button>
                      </div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
