import React, { useState, useEffect } from "react";
import { PieChart, Pie } from "recharts";
import PersonIcon from "@mui/icons-material/Person";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import StoreIcon from "@mui/icons-material/Store";
import Form from "react-bootstrap/Form";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const data = [
    { name: "Bike", value: 10543 },
    { name: "Car", value: 3543 },
    { name: "Taxi", value: 8543 },
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a fetch delay of 2 seconds

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <>
      <div className="right-content w-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="hname">
                <h1>Dashboard</h1>
                <h2>Dashboard Overview</h2>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="hname"></div>
            </div>
            <div className="dtopic col-4">
              <Form.Group className="dform justify-content-end ms-5">
                <Form.Label className="formlabel1">Graph by country</Form.Label>
                <Form.Select className="formselect">
                  <option>USA</option>
                  <option>Nepal</option>
                  <option>Vietnam</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="dashboardBoxWrapper d-flex">
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon">
                  <PersonIcon />
                </span>
              </div>
              <div className="cname">
                <h1>No. of Users</h1>
                <h2>30</h2>
              </div>
            </div>
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon1">
                  <TwoWheelerIcon />
                </span>
              </div>
              <div className="cname">
                <h1>No. of Providers</h1>
                <h2>32</h2>
              </div>
            </div>
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon2">
                  <DeviceHubIcon />
                </span>
              </div>
              <div className="cname">
                <h1>No. of Fleets</h1>
                <h2>0</h2>
              </div>
            </div>
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon3">
                  <StoreIcon />
                </span>
              </div>
              <div className="cname">
                <h1>No. of Shops</h1>
                <h2>0</h2>
              </div>
            </div>
          </div>
          <div className="dashboardBoxWrappers d-flex">
            <div className="dashboardBoxx">
              <div className="xname">
                <h1>Total Transports</h1>
                <div className="pchart">
                  <PieChart width={400} height={400}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={data}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#2075b8"
                      label
                    />
                    <Tooltip />
                  </PieChart>
                </div>
              </div>
            </div>
            <div className="dashboardBoxx">
              <div className="xname">
                <h1>Total Services</h1>
                <div className="bchart">
                  <BarChart
                    width={400}
                    height={220}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 10,
                      bottom: 5,
                    }}
                    barSize={25}
                  >
                    <XAxis
                      dataKey="name"
                      scale="point"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar
                      dataKey="value"
                      fill="#4ea852"
                      background={{ fill: "#eee" }}
                    />
                  </BarChart>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboardBoxWrappers d-flex">
            <div className="dashboardBoxx">
              <div className="xname">
                <h1>Total Orders</h1>
                <div className="bchart">
                  <BarChart
                    width={400}
                    height={220}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 10,
                      bottom: 5,
                    }}
                    barSize={25}
                  >
                    <XAxis
                      dataKey="name"
                      scale="point"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar
                      dataKey="value"
                      fill="#4ea852"
                      background={{ fill: "#eee" }}
                    />
                  </BarChart>
                </div>
              </div>
            </div>
            <div className="dashboardBoxx">
              <div className="xname">
                <h1>Overall Summary</h1>
                <div className="pchart">
                  <PieChart width={400} height={400}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={data}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#2075b8"
                      label
                    />
                    <Tooltip />
                  </PieChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
