import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import clipboardCopy from "clipboard-copy";

function Promocode() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const csvLink = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editIndex, setEditIndex] = useState(null);
  const itemsPerPage = 9;

  const headers = [
    { label: "S.No.", key: "index" },
    { label: "Promocode", key: "promocode" },
    { label: "Picture", key: "picture" },
    { label: "Type", key: "type" },
    { label: "Percentage", key: "percentage" },
    { label: "Max Amount", key: "maxAmount" },
    { label: "Expiration", key: "expiration" },
    { label: "User Eligibility", key: "userEligibility" },
    { label: "Created By", key: "createdBy" },
    { label: "Action", key: "action" },
  ];

  const [formData, setFormData] = useState({
    promocode: "",
    picture: null,
    type: "",
    percentage: "",
    maxAmount: "",
    expiration: "",
    userEligibility: "",
    createdBy: "",
  });

  useEffect(() => {
    const defaultData = [];
    setData(defaultData);
    setIsLoading(false);
  }, []);

  const handleModalToggle = () => {
    setShowModal(!showModal);
    setFormData({
      promocode: "",
      picture: null,
      type: "",
      percentage: "",
      maxAmount: "",
      expiration: "",
      userEligibility: "",
      createdBy: "",
    });
    setEditIndex(null);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "picture") {
      setFormData({
        ...formData,
        picture: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = [...data];
    const newEntry = {
      ...formData,
      picture: formData.picture ? URL.createObjectURL(formData.picture) : null,
      index: editIndex !== null ? data[editIndex].index : data.length + 1,
      action: (
        <>
          <Button
            variant="warning"
            onClick={() =>
              handleEdit(editIndex !== null ? editIndex : data.length)
            }
          >
            Edit
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() =>
              handleDelete(editIndex !== null ? editIndex : data.length)
            }
          >
            Delete
          </Button>
        </>
      ),
    };

    if (editIndex !== null) {
      newData[editIndex] = newEntry;
    } else {
      newData.push(newEntry);
    }

    setData(newData);
    handleModalToggle();
  };

  const handleEdit = (index) => {
    setFormData(data[index]);
    setEditIndex(index);
    setShowModal(true);
  };

  const handleDelete = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table-data.xlsx");
  };

  const exportToPDF = () => {
    const input = document.getElementById("export-table");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("table-data.pdf");
    });
  };

  const copyToClipboard = () => {
    const tableText = data
      .map((row) => headers.map((header) => row[header.key]).join("\t"))
      .join("\n");
    clipboardCopy(tableText);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentss">
        <div className="user1">
          <div className="row">
            <div className="ltopic col-6">Promocode</div>
            <div className="rtopic col-6">
              <Button variant="info" onClick={handleModalToggle}>
                + Add New Promocode
              </Button>
            </div>
          </div>
        </div>

        <div className="user2">
          <div className="row mt-1 mb-2">
            <div className="kkk col-6">
              <div>
                <Button variant="secondary" onClick={copyToClipboard}>
                  Copy
                </Button>
                <Button variant="info ms-2">
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename="table-data.csv"
                    className="btnsss"
                    ref={csvLink}
                    target="_blank"
                  >
                    CSV
                  </CSVLink>
                </Button>
                <Button variant="success ms-2" onClick={exportToExcel}>
                  Excel
                </Button>
                <Button variant="danger ms-2" onClick={exportToPDF}>
                  PDF
                </Button>
              </div>
            </div>
            <div className="kkkk col-6 d-flex">
              <h1>Search:</h1>
              <Form.Control
                className="searchh"
                type="text"
                placeholder=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Container>
          <Modal show={showModal} onHide={handleModalToggle}>
            <Modal.Header closeButton>
              <Modal.Title>
                {editIndex !== null ? "Edit Promocode" : "Add Promocode"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">
              <Form onSubmit={handleSubmit} className="mb-3 mt-1 ">
                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Promocode</Form.Label>
                      <Form.Control
                        type="text"
                        name="promocode"
                        value={formData.promocode}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Picture</Form.Label>
                      <Form.Control
                        type="file"
                        name="picture"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        type="text"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Percentage</Form.Label>
                      <Form.Control
                        type="text"
                        name="percentage"
                        value={formData.percentage}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Max Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="maxAmount"
                        value={formData.maxAmount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Expiration</Form.Label>
                      <Form.Control
                        type="text"
                        name="expiration"
                        value={formData.expiration}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>User Eligibility</Form.Label>
                      <Form.Control
                        type="text"
                        name="userEligibility"
                        value={formData.userEligibility}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Created By</Form.Label>
                      <Form.Control
                        type="text"
                        name="createdBy"
                        value={formData.createdBy}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="d-flex align-items-end mt-3">
                    <Button type="submit" variant="info">
                      {editIndex !== null
                        ? "Update Promocode"
                        : "Add Promocode"}
                    </Button>
                    <Button variant="danger ms-4" onClick={handleModalToggle}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          <Table striped bordered hover responsive id="export-table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{item.index}</td>
                  <td>{item.promocode}</td>
                  <td>
                    {item.picture && (
                      <img
                        src={item.picture}
                        alt="Promocode"
                        style={{ width: "50px", height: "50px" }}
                      />
                    )}
                  </td>
                  <td>{item.type}</td>
                  <td>{item.percentage}</td>
                  <td>{item.maxAmount}</td>
                  <td>{item.expiration}</td>
                  <td>{item.userEligibility}</td>
                  <td>{item.createdBy}</td>
                  <td>{item.action}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        <div className="pagination">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="btopic1">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                {filteredData.length} entries
              </h1>
            </div>
            <div className="col-lg-6 mover">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  variant={
                    index + 1 === currentPage
                      ? "secondary"
                      : "outline-secondary"
                  }
                  className="me-2"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                variant="outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promocode;
