import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import { createContext, useEffect, useState } from "react";
import Dispatcher from "./pages/Dispatcher";
import Heat from "./pages/Heat";
import God from "./pages/God";
import Users from "./pages/Users";
import Providers from "./pages/Providers";
import Fleet from "./pages/Fleet";
import Dmanager from "./pages/Dmanager";
import Dispute from "./pages/dispute";
import Disputeor from "./pages/Disputeor";
import Disputer from "./pages/Disputer";
import Disputeri from "./pages/Disputeri";
import Account from "./pages/Account";
import Disputem from "./pages/Disputem";
import Transport from "./pages/Transport";
import Site from "./pages/Site";
import Customs from "./pages/Customs";
import Roletypes from "./pages/Roletypes";
import Zones from "./pages/Zones";
import Userrating from "./pages/Userrating";
import Profile from "./pages/Profile";
import Password from "./pages/Password";
import Administrators from "./pages/Administrators";
import Payroll from "./pages/Payroll";
import Providerrating from "./pages/Providerrating";
import Promocode from "./pages/Promocode";
import Documents from "./pages/Documents";
import Units from "./pages/Units";
import Cancel from "./pages/Cancel";
import Notification from "./pages/Notification";
import Lost from "./pages/Lost";
import Transporttypes from "./pages/Transporttypes";
import Vehicletypes from "./pages/Vehicletypes";
import Peakhour from "./pages/Peakhour";
import Requesthistory from "./pages/Requesthistory";
import Requestschedule from "./pages/Requestschedule";
import Deliverytypes from "./pages/Deliverytypes";
import Vehicletype from "./pages/Vehicletype";
import Packagetypes from "./pages/Packagetypes";
import Categories from "./pages/Categories";
import Subcategories from "./pages/Subcategories";
import Services from "./pages/Services";
import Shoptype from "./pages/Shoptype";
import Cuisines from "./pages/Cuisines";
import Shop from "./pages/Shop";
import Geo from "./pages/Geo";
import Businesscountry from "./pages/Businesscountry";
import Businesscity from "./pages/Businesscity";
import Menu from "./pages/Menu";
import Ticketcategory from "./pages/Ticketcategory";
import Ticket from "./pages/Ticket";
import Custompush from "./pages/Custompush";
import Servicestatement from "./pages/Servicestatement";
import Orderstatement from "./pages/Orderstatement";
import Admintransaction from "./pages/Admintransaction";
import Fleettransaction from "./pages/Fleettransaction";
import Userstatement from "./pages/Userstatement";
import Providerstatement from "./pages/Providerstatement";
import Disputemanager from "./pages/Disputemanager";

const MyContext = createContext();

function App() {
  const [isToggleSidebar, setIsToggleSidebar] = useState(false);
  const values = {
    isToggleSidebar,
    setIsToggleSidebar,
  };

  return (
    <BrowserRouter>
      <MyContext.Provider value={values}>
        <Header />
        <div className="main d-flex">
          <div
            className={`sidebarWrapper ${
              isToggleSidebar === true ? "toggle" : ""
            }`}
          >
            <Sidebar />
          </div>

          <div
            className={`content ${isToggleSidebar === true ? "toggle" : ""}`}
          >
            <Routes>
              <Route path="/" exact={true} element={<Dashboard />} />
              <Route path="/dashboard" exact={true} element={<Dashboard />} />
              <Route path="/dispatcher" exact={true} element={<Dispatcher />} />
              <Route path="/dispute" exact={true} element={<Dispute />} />
              <Route path="/disputer" exact={true} element={<Disputer />} />
              <Route path="/disputeri" exact={true} element={<Disputeri />} />
              <Route path="/disputeor" exact={true} element={<Disputeor />} />
              <Route path="/account" exact={true} element={<Account />} />
              <Route path="/heat" exact={true} element={<Heat />} />
              <Route path="/god" exact={true} element={<God />} />
              <Route path="/users" exact={true} element={<Users />} />
              <Route path="/providers" exact={true} element={<Providers />} />
              <Route path="/fleet" exact={true} element={<Fleet />} />
              <Route path="/dmanager" exact={true} element={<Dmanager />} />
              <Route path="/disputem" exact={true} element={<Disputem />} />
              <Route path="/transport" exact={true} element={<Transport />} />
              <Route path="/site" exact={true} element={<Site />} />
              <Route path="/customs" exact={true} element={<Customs />} />
              <Route path="/roletypes" exact={true} element={<Roletypes />} />
              <Route path="/zones" exact={true} element={<Zones />} />
              <Route path="/userrating" exact={true} element={<Userrating />} />
              <Route
                path="/providerrating"
                exact={true}
                element={<Providerrating />}
              />
              <Route path="/profile" exact={true} element={<Profile />} />
              <Route path="/password" exact={true} element={<Password />} />
              <Route
                path="/administrators"
                exact={true}
                element={<Administrators />}
              />
              <Route path="/payroll" exact={true} element={<Payroll />} />
              <Route path="/promocode" exact={true} element={<Promocode />} />
              <Route path="/documents" exact={true} element={<Documents />} />
              <Route path="/units" exact={true} element={<Units />} />
              <Route path="/cancel" exact={true} element={<Cancel />} />
              <Route
                path="/notification"
                exact={true}
                element={<Notification />}
              />
              <Route path="/lost" exact={true} element={<Lost />} />
              <Route
                path="/transporttypes"
                exact={true}
                element={<Transporttypes />}
              />
              <Route
                path="/vehicletypes"
                exact={true}
                element={<Vehicletypes />}
              />
              <Route path="/peakhour" exact={true} element={<Peakhour />} />
              <Route
                path="/requesthistory"
                exact={true}
                element={<Requesthistory />}
              />
              <Route
                path="/requestschedule"
                exact={true}
                element={<Requestschedule />}
              />
              <Route
                path="/deliverytypes"
                exact={true}
                element={<Deliverytypes />}
              />
              <Route
                path="/vehicletype"
                exact={true}
                element={<Vehicletype />}
              />

              <Route
                path="/packagetypes"
                exact={true}
                element={<Packagetypes />}
              />
              <Route path="/categories" exact={true} element={<Categories />} />
              <Route
                path="/subcategories"
                exact={true}
                element={<Subcategories />}
              />
              <Route path="/services" exact={true} element={<Services />} />
              <Route path="/shoptype" exact={true} element={<Shoptype />} />
              <Route path="/cuisines" exact={true} element={<Cuisines />} />
              <Route path="/shop" exact={true} element={<Shop />} />
              <Route path="/geo" exact={true} element={<Geo />} />
              <Route
                path="/businesscountry"
                exact={true}
                element={<Businesscountry />}
              />
              <Route
                path="/businesscity"
                exact={true}
                element={<Businesscity />}
              />
              <Route path="/menu" exact={true} element={<Menu />} />
              <Route
                path="/ticketcategory"
                exact={true}
                element={<Ticketcategory />}
              />
              <Route path="/ticket" exact={true} element={<Ticket />} />
              <Route path="/custompush" exact={true} element={<Custompush />} />
              <Route
                path="/servicestatement"
                exact={true}
                element={<Servicestatement />}
              />
              <Route
                path="/orderstatement"
                exact={true}
                element={<Orderstatement />}
              />
              <Route
                path="/admintransaction"
                exact={true}
                element={<Admintransaction />}
              />
              <Route
                path="/fleettransaction"
                exact={true}
                element={<Fleettransaction />}
              />
              <Route
                path="/userstatement"
                exact={true}
                element={<Userstatement />}
              />
              <Route
                path="/providerstatement"
                exact={true}
                element={<Providerstatement />}
              />
              <Route
                path="/disputemanager"
                exact={true}
                element={<Disputemanager />}
              />
            </Routes>
          </div>
        </div>
        <Footer />
      </MyContext.Provider>
    </BrowserRouter>
  );
}

export default App;
export { MyContext };
