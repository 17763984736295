import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";

export default function God() {
  const [key, setKey] = useState("Enroute");
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="right-content">
      <div className="dispatchers">
        <h1>Request List</h1>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="TRANSPORT" value="1" />
                <Tab label="ORDER" value="2" />
                <Tab label="SERVICE" value="3" />
                <Tab label="DELIVERY" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="Enroute" title="Enroute to Pickup">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Enroute to Pickup</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Reached" title="Reached Pickup">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Reached Pickup</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Journey" title="Journey Started">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Journey Started</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="available" title="Available">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Available</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="all" title="All">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>All</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </TabPanel>
            <TabPanel value="2">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="Enroute" title="Enroute to Restaurent">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Enroute to Restaurent</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Reached" title="Reached Restaurent">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Reached Restaurent</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Journey" title="Picked up Food">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Picked up Food</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="available" title="Reached to Delivery Location">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Reached to Delivery Location</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="all" title="Available">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Available</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="alll" title="All">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>All</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </TabPanel>
            <TabPanel value="3">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="Enroute" title="Reached Location">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Reached Location</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Reached" title="Started Service">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Started Service</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Journey" title="End Service">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>End Service</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="all" title="Available">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Available</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="alll" title="All">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>All</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </TabPanel>
            <TabPanel value="4">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="Enroute" title="Reached Location">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Reached Location</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Reached" title="Started Service">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Started Service</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Journey" title="End Service">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>End Service</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="all" title="Available">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Available</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="alll" title="All">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>All</h2>
                          </div>
                          <div className="bbody"></div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}
