import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/image/logo/kantipur_logo-removebg-preview.png";
import Button from "@mui/material/Button";
import { MdMenuOpen } from "react-icons/md";
import { MdOutlineMenu } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import profile from "../../assets/image/person/pp.png";
import Logout from "@mui/icons-material/Logout";
import { PersonAdd } from "@mui/icons-material";
import { FaShieldAlt } from "react-icons/fa";
import { MyContext } from "../../App";
import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const context = useContext(MyContext);

  const handleOpenmyaccDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosemyaccDrop = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <header className="d-flex align-items-center">
        <div className="container-fluid w-100">
          <div className="row d-flex align-items-center">
            <div className="col-2 part1 w- ttt">
              <Link to={"/"} className="d-flex align-items-center logo">
                <img src={logo} />
              </Link>
            </div>
            <div className="col-3 align-items-center part2">
              <Button
                className="button button1 mr-3 "
                onClick={() =>
                  context.setIsToggleSidebar(!context.isToggleSidebar)
                }
              >
                {context.isToggleSidebar === false ? (
                  <MdMenuOpen />
                ) : (
                  <MdOutlineMenu />
                )}
              </Button>
            </div>

            <div className="col-7 d-flex align-items-center justify-content-end part3">
              <div className="adminss align-items-center justify-content-end d-flex container-fluid">
                <div className="buttonss">
                  <Button className="rounded-circle  ">
                    <MdLightMode />
                  </Button>
                  <Button className="rounded-circle  ">
                    <FaRegBell />
                  </Button>
                  <Button className="rounded-circle ">
                    <MdOutlineMailOutline />
                  </Button>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="#203548 " id="dropdown-basic">
                    <img src={profile} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">
                      <span className="aicon1">{<PersonAdd />}</span>
                      <span className="atext ms-2">Profile</span>
                    </Dropdown.Item>

                    <Dropdown.Item as={Link} to="/password">
                      <span className="aicon1">{<FaShieldAlt />}</span>
                      <span className="atext ms-2">Change Password</span>
                    </Dropdown.Item>

                    <Dropdown.Item as={Link} to="/logout">
                      <span className="aicon3">{<Logout />}</span>
                      <span className="atext ms-2">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
