import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import clipboardCopy from "clipboard-copy";
import { MdLocalTaxi } from "react-icons/md";
import { MdAttachMoney } from "react-icons/md";
import { MdCancelPresentation } from "react-icons/md";

function Orderstatement() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const csvLink = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [date, setDate] = useState("");

  const headers = [
    { label: "S.No.", key: "index" },
    { label: "Booking Id", key: "BookingId" },
    { label: "User Name", key: "userName" },
    { label: "Provider Name", key: "providerName" },
    { label: "Date & Time", key: "dateTime" },
    { label: "Status", key: "status" },
    { label: "Amount", key: "amount" },
    { label: "Commission", key: "commission" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Action", key: "action" },
  ];

  const [formData, setFormData] = useState({
    BookingId: "",
    userName: "",
    providerName: "",
    datetime: "",
    status: "",
    amount: "",
    commission: "",
    paymentStatus: "",
    action: "",
  });

  useEffect(() => {
    const defaultData = [];
    setData(defaultData);
  }, []);

  const handleModalToggle = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(
      data.concat({ ...formData, index: data.length + 1, action: "N/A" })
    );
    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      rating: "",
      wallet: "",
      status: "",
    });
    handleModalToggle();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table-data.xlsx");
  };

  const exportToPDF = () => {
    const input = document.getElementById("export-table");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("table-data.pdf");
    });
  };

  const copyToClipboard = () => {
    const tableText = data
      .map((row) => headers.map((header) => row[header.key]).join("\t"))
      .join("\n");
    clipboardCopy(tableText);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a fetch delay of 2 seconds

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentss">
        <div className="user1">
          <div className=" row">
            <div className="ltopic mb-3 col-6">Overall Order Statements</div>
            <div className="kkkk col-6"></div>
          </div>
        </div>

        <div className="transport-container">
          <div className="transport d-flex">
            <Button className="btansport btn btn-info ms-3 ">Today</Button>
            <Button className="btansport btn btn-info">Yesterday</Button>
            <Button className="btansport btn btn-info">Current Week</Button>
            <Button className="btansport btn btn-info">Previous Week</Button>
            <Button className="btansport btn btn-info">Current Month</Button>
            <Button className="btansport btn btn-info">Previous Month</Button>
            <Button className="btansport btn btn-info">Current Year</Button>
            <Button className="btansport btn btn-info">Previous Year</Button>
          </div>
        </div>

        <div className="ttransport mt-5 mb-5">
          <div className="row">
            <div className="csub col-3">
              <h1>Date From*</h1>
              <form onSubmit={handleSubmit}>
                <div className="ppp">
                  <input
                    type="date"
                    id="dateInput"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="csub col-3">
              <h1>Date To*</h1>
              <form onSubmit={handleSubmit}>
                <div className="ppp">
                  <input
                    type="date"
                    id="dateInput"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="csub col-3">
              <h1>Country</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>USA</option>
                    <option>Nepal</option>
                    <option>Vietnam</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="csub col-3">
              <h1>Fleet</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>Select</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row mt-5">
            <div className="csub col-3">
              <h1>Status</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>Select</option>
                    <option>Cancelled</option>
                    <option>Completed</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="csub col-3">
              <h1>User</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>Select</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="csub col-3">
              <h1>Provider</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>Select</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="csub col-3">
              <h1>Service Type</h1>
              <Form.Group className="ssform">
                <div>
                  <Form.Select>
                    <option>Select</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="tdown mt-5">
            <Button>Reset</Button>
            <Button>Search</Button>
          </div>

          <div className="dashboardBoxWrapper d-flex">
            <div className="dashboardBox d-flex ms-5">
              <div className="card-icon">
                <span className="icon">
                  <MdLocalTaxi />
                </span>
              </div>
              <div className="cname">
                <h1>TOTAL NO. OF RIDES</h1>
                <h2>0</h2>
              </div>
            </div>
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon">
                  <MdAttachMoney />
                </span>
              </div>
              <div className="cname">
                <h1>REVENUE</h1>
                <h2>0</h2>
              </div>
            </div>
            <div className="dashboardBox d-flex">
              <div className="card-icon">
                <span className="icon">
                  <MdCancelPresentation />
                </span>
              </div>
              <div className="cname">
                <h1>CANCELLED RIDES</h1>
                <h2>0</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="user2">
          <div className=" row mt-1 mb-2">
            <div className="kkk col-6">
              <div>
                <Button variant="secondary" onClick={copyToClipboard}>
                  Copy
                </Button>
                <Button variant="info ms-2">
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename="table-data.csv"
                    className="btnsss"
                    ref={csvLink}
                    target="_blank"
                  >
                    CSV
                  </CSVLink>
                </Button>
                <Button variant="success ms-2" onClick={exportToExcel}>
                  Excel
                </Button>
                <Button variant="danger ms-2" onClick={exportToPDF}>
                  PDF
                </Button>
              </div>
            </div>
            <div className="kkkk col-6 d-flex">
              <h1>Search:</h1>
              <Form.Control
                className="searchh"
                type="text"
                placeholder=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Container>
          <Modal show={showModal} onHide={handleModalToggle}>
            <Modal.Header closeButton>
              <Modal.Title>Add New User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">
              <Form onSubmit={handleSubmit} className="mb-3 mt-1 ">
                <Row>
                  {Object.keys(formData).map((key, idx) => (
                    <Col key={idx} md={12}>
                      <Form.Group className="mt-3">
                        <Form.Label>{headers[idx + 1].label}</Form.Label>
                        <Form.Control
                          type="text"
                          name={key}
                          value={formData[key]}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  ))}
                  <Col md={12} className="d-flex align-items-end mt-3">
                    <Button type="submit" variant="success">
                      Add User
                    </Button>
                    <Button variant="danger ms-4" onClick={handleModalToggle}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          <Table striped bordered hover responsive id="export-table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{item.index}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>{item.rating}</td>
                  <td>{item.wallet}</td>
                  <td>{item.status}</td>
                  <td>{item.action}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        <div className="pagination">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="btopic1">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                {filteredData.length} entries
              </h1>
            </div>
            <div className="col-lg-6 mover">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  variant={
                    index + 1 === currentPage
                      ? "secondary"
                      : "outline-secondary"
                  }
                  className="me-2"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                variant="outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orderstatement;
