import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function Password() {
  const [isLoading, setIsLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleUpdateProfile = () => {
    if (newPassword !== retypeNewPassword) {
      Swal.fire({
        icon: "error",
        title: "Password Error",
        text: "Please re-enter Password",
      });
      return;
    }

    console.log("Old Password:", oldPassword);
    console.log("New Password:", newPassword);
    console.log("Retype New Password:", retypeNewPassword);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentsss">
        <div className="container-fluid">
          <h1>Change Password</h1>
          <div className="profileform">
            <Form>
              <div className="formss mt-4 p-2">
                <Form.Group controlId="formOldPassword">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter old password"
                  />
                </Form.Group>
              </div>

              <div className="formss mt-3 p-2">
                <Form.Group controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                </Form.Group>
              </div>

              <div className="formss mt-3 p-2">
                <Form.Group controlId="formRetypeNewPassword">
                  <Form.Label>Retype New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={retypeNewPassword}
                    onChange={(e) => setRetypeNewPassword(e.target.value)}
                    placeholder="Retype new password"
                  />
                </Form.Group>
              </div>

              <Button variant="primary mt-4 ms-1" onClick={handleUpdateProfile}>
                Update Password
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
