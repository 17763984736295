import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function Dispatcher() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="right-content">
      <div className="dispatcher">
        <h1>Request List</h1>
        <div className="container-fluid">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="Searching" value="1" sx={{ fontSize: "12px" }} />
                  <Tab label="Assigned" value="2" sx={{ fontSize: "12px" }} />
                  <Tab label="Add" value="3" sx={{ fontSize: "12px" }} />
                </TabList>
              </Box>

              <TabPanel value="1">
                <div className="distab">
                  <div className="tabcontent1">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="ttitle">
                            <h2>Searching List</h2>
                          </div>
                          <div className="bbody">
                            <h3>No request received</h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="ttitle">
                            <h2>Map</h2>
                          </div>
                          <div className="bbody">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                              width="650"
                              height="480"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Map of Kathmandu"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value="2">
                <div className="tabcontent2">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-4">
                        <div className="ttitle">
                          <h2>Assigned List</h2>
                        </div>
                        <div className="bbody"></div>
                      </div>
                      <div className="col-8">
                        <div className="ttitle">
                          <h2>Map</h2>
                        </div>
                        <div className="bbody">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                            width="650"
                            height="480"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Map of Kathmandu"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="tabcontent3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-4">
                        <div className="ttitle">
                          <h2>Ride Details</h2>
                        </div>
                        <div className="bbody">
                          <Form>
                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicText"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicText"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" placeholder="Email" />
                            </Form.Group>

                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicNumber"
                            >
                              <Form.Label>Phone</Form.Label>
                              <Form.Control type="tel" placeholder="phone" />
                            </Form.Group>

                            <Form.Group className="mb-1 p-2">
                              <Form.Label>Select Country</Form.Label>
                              <Form.Select>
                                <option>Nepal</option>
                                <option>United States</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-1 p-2">
                              <Form.Label>Select City</Form.Label>
                              <Form.Select>
                                <option>Kathmandu</option>
                                <option>Pokhara</option>
                                <option>Lalitpur</option>
                                <option>Kirtipur</option>
                                <option>Surkhet</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-1 p-2">
                              <Form.Label>Main Service</Form.Label>
                              <Form.Select>
                                <option>Transport</option>
                                <option>Service</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicText"
                            >
                              <Form.Label>Pickup Address</Form.Label>
                              <Form.Control type="text" placeholder="" />
                            </Form.Group>

                            <Form.Group
                              className="mb-1 p-2 "
                              controlId="formBasicText"
                            >
                              <Form.Label>Dropoff Address</Form.Label>
                              <Form.Control type="text" placeholder="" />
                            </Form.Group>

                            <div className="buttons">
                              <div className="row">
                                <div className="button1 col-6">
                                  <Button variant="danger" type="submit">
                                    Cancel
                                  </Button>
                                </div>
                                <div className="button2 col-6">
                                  <Button variant="primary" type="submit">
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="ttitle">
                          <h2>Map</h2>
                        </div>
                        <div className="bbody">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                            width="650"
                            height="480"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Map of Kathmandu"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
