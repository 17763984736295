import Button from "@mui/material/Button";
import { MdDashboard } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { FaTruckMoving } from "react-icons/fa6";
import { FaRunning } from "react-icons/fa";
import { IoMdMap } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { FaTaxi } from "react-icons/fa";
import { FaPaintRoller } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { MdFace } from "react-icons/md";
import { IoAccessibilitySharp } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { FaMotorcycle } from "react-icons/fa";
import { MdDirectionsBoatFilled } from "react-icons/md";
import { MdEventSeat } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdOutlineTransferWithinAStation } from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { FaListAlt } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { IoListSharp } from "react-icons/io5";
import { MdCardMembership } from "react-icons/md";
import { MdPublic } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";
import { LiaStarSolid } from "react-icons/lia";
import { MdLocalActivity } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdSyncDisabled } from "react-icons/md";
import { MdDirectionsCar } from "react-icons/md";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { MdTimelapse } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { MdAccessAlarms } from "react-icons/md";
import { MdAllInbox } from "react-icons/md";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdApps } from "react-icons/md";
import { MdOutlineSubject } from "react-icons/md";
import { MdLocalCarWash } from "react-icons/md";
import { MdInput } from "react-icons/md";
import { MdOutlineShoppingBasket } from "react-icons/md";
import { IoMdRestaurant } from "react-icons/io";
import { IoStorefrontSharp } from "react-icons/io5";
import { MdOutlineTransitEnterexit } from "react-icons/md";
import { MdSettingsApplications } from "react-icons/md";
import { MdLocationCity } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";
import { MdPages } from "react-icons/md";
import { MdChatBubble } from "react-icons/md";
import { MdTextsms } from "react-icons/md";
import { MdKeyboardTab } from "react-icons/md";
import { MyContext } from "../../App";
import { useLocation } from "react-router-dom";
const Sidebar = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isToggleSubmenu, setIsToggleSubmenu] = useState(false);
  const location = useLocation();

  const context = useContext(MyContext);

  const isOpenSubmenu = (index) => {
    setActiveTab(index);
    setIsToggleSubmenu(!isToggleSubmenu);
  };
  console.log(location.pathname === "/dispute");
  console.log(location.pathname === "/disputer");
  console.log(location.pathname === "/disputeri");
  console.log(location.pathname === "/disputeor");
  console.log(location.pathname === "/transport");
  console.log(location.pathname === "/roles");
  console.log(location.pathname === "/roletypes");
  console.log(location.pathname === "/zones");
  console.log(location.pathname === "/payroll");
  console.log(location.pathname === "/userrating");
  console.log(location.pathname === "/providerrating");
  console.log(location.pathname === "/administrators");
  console.log(location.pathname === "/payroll");
  console.log(location.pathname === "/transporttypes");
  console.log(location.pathname === "/vehicletypes");
  console.log(location.pathname === "/peakhour");
  console.log(location.pathname === "/deliverytypes");
  console.log(location.pathname === "/vehicletypes");
  console.log(location.pathname === "/packagetypes");
  console.log(location.pathname === "/categories");
  console.log(location.pathname === "/subcategories");
  console.log(location.pathname === "/services");

  return (
    <>
      <div className="sidebar">
        <ul className="1">
          <h1>ADMIN DASHBOARD</h1>
          <li>
            <Link to={"/dashboard"}>
              <Button
                className={`w-100 ${activeTab === 0 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(0);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdDashboard />
                </span>
                DashBoard
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/dispatcher"}>
              <Button
                className={`w-100 ${activeTab === 1 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(1);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaTruckMoving />
                </span>
                Dispatcher Panel
              </Button>
            </Link>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 2 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(2)}
            >
              <span className="icon">
                <FaRunning />
              </span>
              Dispute Panel
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 2 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/disputer" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(2);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/disputer">
                    <span className="icon">
                      <BsPersonCircle />
                    </span>
                    Dispute Reason
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/disputeri" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(2);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/disputeri">
                    <span className="icon">
                      <FaTaxi />
                    </span>
                    Ride Dispute Requests
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/dispute" ? "activeLiii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(2);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/dispute">
                    <span className="icon">
                      <FaPaintRoller />
                    </span>
                    Service Dispute Requests
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/disputeor" ? "activeLiiii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(2);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/disputeor">
                    <span className="icon">
                      <FaShoppingCart />
                    </span>
                    Order Dispute Requests
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to={"/heat"}>
              <Button
                className={`w-100 ${activeTab === 3 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(3);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoMdMap />
                </span>
                Heat Map
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/god"}>
              <Button
                className={`w-100 ${activeTab === 4 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(4);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoEyeSharp />
                </span>
                God's View
              </Button>
            </Link>
          </li>
        </ul>

        <ul className="2">
          <h1>MEMBERS</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 5 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(5)}
            >
              <span className="icon">
                <MdFace />
              </span>
              Roles
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 5 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/roletypes" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(5);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/roletypes">
                    <span className="icon">
                      <IoAccessibilitySharp />
                    </span>
                    Role Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/administrators" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(5);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/administrators">
                    <span className="icon">
                      <MdAccountCircle />
                    </span>
                    Administrators
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to={"/users"}>
              <Button
                className={`w-100 ${activeTab === 6 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(6);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoPerson />
                </span>
                Users
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/providers"}>
              <Button
                className={`w-100 ${activeTab === 7 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(7);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaMotorcycle />
                </span>
                Providers
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/fleet"}>
              <Button
                className={`w-100 ${activeTab === 8 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(8);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdDirectionsBoatFilled />
                </span>
                Fleet Owner
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/dmanager"}>
              <Button
                className={`w-100 ${activeTab === 9 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(9);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdEventSeat />
                </span>
                Dispatcher Manager
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/account"}>
              <Button
                className={`w-100 ${activeTab === 10 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(10);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdOutlineAccountBalanceWallet />
                </span>
                Account Manager
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/disputemanager"}>
              <Button
                className={`w-100 ${activeTab === 11 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(11);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdOutlineTransferWithinAStation />
                </span>
                Dispute Manager
              </Button>
            </Link>
          </li>
        </ul>

        <ul className="3">
          <h1>ACCOUNTS</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 12 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(12)}
            >
              <span className="icon">
                <MdAccountBalance />
              </span>
              Statement
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 12 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/transport" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/transport">
                    <span className="icon">
                      <FaListAlt />
                    </span>
                    Transport Statement
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/servicestatement" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/servicestatement">
                    <span className="icon">
                      <FaListAlt />
                    </span>
                    Services Statement
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/orderstatement" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/orderstatement">
                    <span className="icon">
                      <FaListAlt />
                    </span>
                    Orders Statement
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/userstatement" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/userstatement">
                    <span className="icon">
                      <IoMdPerson />
                    </span>
                    User Statement
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/providerstatement" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/providerstatement">
                    <span className="icon">
                      <FaMotorcycle />
                    </span>
                    Provider Statement
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/admintransaction" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/admintransaction">
                    <span className="icon">
                      <IoListSharp />
                    </span>
                    Admin Transaction
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/fleettransaction" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/fleettransaction">
                    <span className="icon">
                      <IoListSharp />
                    </span>
                    Fleet Transaction
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 13 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(13)}
            >
              <span className="icon">
                <MdCardMembership />
              </span>
              Payrolls
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 13 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/zones" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(13);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/zones">
                    <span className="icon">
                      <MdPublic />
                    </span>
                    Zones
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/payroll" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(12);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/payroll">
                    <span className="icon">
                      <MdBusinessCenter />
                    </span>
                    Payroll
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <ul className="4">
          <h1>DETAILS</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 14 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(14)}
            >
              <span className="icon">
                <LiaStarSolid />
              </span>
              Ratings & Reviews
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 14 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/userrating" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(14);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/userrating">
                    <span className="icon">
                      <IoMdPerson />
                    </span>
                    User Ratings
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/providerrating" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(14);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/providerrating">
                    <span
                      className="icon
                    
                    "
                    >
                      <FaMotorcycle />
                    </span>
                    Provider Ratings
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul className="5">
          <h1>OFFER</h1>
          <li>
            <Link to={"/promocode"}>
              <Button
                className={`w-100 ${activeTab === 15 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(15);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdLocalActivity />
                </span>
                Promocodes
              </Button>
            </Link>
          </li>
        </ul>
        <ul className="6">
          <h1>GENERAL</h1>
          <li>
            <Link to={"/documents"}>
              <Button
                className={`w-100 ${activeTab === 16 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(16);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaListAlt />
                </span>
                Documents
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/units"}>
              <Button
                className={`w-100 ${activeTab === 17 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(17);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaListAlt />
                </span>
                Units
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/cancel"}>
              <Button
                className={`w-100 ${activeTab === 18 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(18);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdCancel />
                </span>
                Cancel Reason
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/notification"}>
              <Button
                className={`w-100 ${activeTab === 19 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(19);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoIosNotifications />
                </span>
                Notification
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/lost"}>
              <Button
                className={`w-100 ${activeTab === 20 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(20);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdSyncDisabled />
                </span>
                Lost Item
              </Button>
            </Link>
          </li>
        </ul>
        <ul className="7">
          <h1>RIDES</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 21 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(21)}
            >
              <span className="icon">
                <MdDirectionsCar />
              </span>
              Transport
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 21 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/transporttypes" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(21);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/transporttypes">
                    <span className="icon">
                      <MdAirlineSeatReclineExtra />
                    </span>
                    Transport Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/vehicletypes" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(21);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/vehicletypes">
                    <span className="icon">
                      <MdDirectionsCar />
                    </span>
                    Vehicle Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/peakhour" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(21);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/peakhour">
                    <span className="icon">
                      <MdTimelapse />
                    </span>
                    Peak Hours
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to={"/requesthistory"}>
              <Button
                className={`w-100 ${activeTab === 22 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(22);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaHistory />
                </span>
                Requests History
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/requestschedule"}>
              <Button
                className={`w-100 ${activeTab === 23 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(23);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdAccessAlarms />
                </span>
                Scheduled Rides
              </Button>
            </Link>
          </li>
        </ul>
        <ul className="8">
          <h1>DELIVERY</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 24 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(24)}
            >
              <span className="icon">
                <MdDirectionsCar />
              </span>
              Delivery
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 24 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/deliverytypes" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(24);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/deliverytypes">
                    <span className="icon">
                      <MdAirlineSeatReclineExtra />
                    </span>
                    Delivery Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/vehicletype" ? "activeLii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(24);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/vehicletype">
                    <span className="icon">
                      <MdDirectionsCar />
                    </span>
                    Vehicle Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/packagetypes" ? "activeLiii" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(24);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/packagetypes">
                    <span className="icon">
                      <MdAllInbox />
                    </span>
                    Package Types
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to={"/requesthistory"}>
              <Button
                className={`w-100 ${activeTab === 25 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(25);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <FaHistory />
                </span>
                Delivery History
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/requestschedule"}>
              <Button
                className={`w-100 ${activeTab === 26 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(26);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdAccessAlarms />
                </span>
                Scheduled Delivery
              </Button>
            </Link>
          </li>
        </ul>
        <ul className="9">
          <h1>XUBER</h1>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 27 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(27)}
            >
              <span className="icon">
                <MdOutlineLocalLaundryService />
              </span>
              Services
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 27 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/categories" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(27);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/categories">
                    <span className="icon">
                      <MdApps />
                    </span>
                    Categories
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/subcategories" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(27);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/subcategories">
                    <span className="icon">
                      <MdOutlineSubject />
                    </span>
                    Sub Categories
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/services" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(27);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/services">
                    <span className="icon">
                      <MdLocalCarWash />
                    </span>
                    Services
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 28 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(28)}
            >
              <span className="icon">
                <MdInput />
              </span>
              Service Requests
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 28 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/requesthistory" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(28);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="requesthistory">
                    <span className="icon">
                      <FaHistory />
                    </span>
                    Requests History
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/requestschedule" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(28);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/requestschedule">
                    <span className="icon">
                      <MdAccessAlarms />
                    </span>
                    Scheduled Services
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul className="10">
          <h1>STORE</h1>
          <li>
            <Link to={"/shoptype"}>
              <Button
                className={`w-100 ${activeTab === 29 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(29);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdOutlineShoppingBasket />
                </span>
                Shop Type
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/cuisines"}>
              <Button
                className={`w-100 ${activeTab === 30 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(30);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoMdRestaurant />
                </span>
                Cuisines
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/shop"}>
              <Button
                className={`w-100 ${activeTab === 31 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(31);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoStorefrontSharp />
                </span>
                Shops
              </Button>
            </Link>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 32 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(32)}
            >
              <span className="icon">
                <MdOutlineTransitEnterexit />
              </span>
              Order Requests
              <span className="arrow">
                <IoIosArrowForward />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 32 && isToggleSubmenu === true
                  ? "collapsed"
                  : "collapse"
              }`}
            >
              <ul className="submenu">
                <li
                  className={`${
                    location.pathname == "/requesthistory" ? "activeLi" : ""
                  }`}
                  onClick={() => {
                    isOpenSubmenu(32);
                    context.setIsToggleSidebar(true);
                  }}
                >
                  <Link to="/requesthistory">
                    <span className="icon">
                      <FaHistory />
                    </span>
                    Requests History
                    <span className="arrow">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul className="11">
          <h1>SETTINGS</h1>
          <li>
            <Link to={"/site"}>
              <Button
                className={`w-100 ${activeTab === 33 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(33);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdSettingsApplications />
                </span>
                Site Settings
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/geo"}>
              <Button
                className={`w-100 ${activeTab === 34 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(34);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoMdMap />
                </span>
                Geo Fencing
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/businesscountry"}>
              <Button
                className={`w-100 ${activeTab === 35 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(35);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdLocationCity />
                </span>
                Business Country
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/businesscity"}>
              <Button
                className={`w-100 ${activeTab === 36 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(36);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdLocationCity />
                </span>
                Business City
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/menu"}>
              <Button
                className={`w-100 ${activeTab === 37 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(37);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <IoMdMenu />
                </span>
                Menus
              </Button>
            </Link>
          </li>
        </ul>
        <ul className="12">
          <h1>OTHERS</h1>
          <li>
            <Link to={"/customs"}>
              <Button
                className={`w-100 ${activeTab === 38 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(38);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdPages />
                </span>
                CMS Pages
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/ticketcategory">
              <Button
                className={`w-100 ${activeTab === 39 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(39);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdChatBubble />
                </span>
                Ticket Categories
              </Button>
            </Link>
          </li>
          <li>
            <Link to={"/ticket"}>
              <Button
                className={`w-100 ${activeTab === 40 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(40);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdTextsms />
                </span>
                Tickets
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/custompush">
              <Button
                className={`w-100 ${activeTab === 41 ? "active" : ""}`}
                onClick={() => {
                  isOpenSubmenu(41);
                  context.setIsToggleSidebar(true);
                }}
              >
                <span className="icon">
                  <MdKeyboardTab />
                </span>
                Custom Push
              </Button>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
