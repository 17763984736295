import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function Heat() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="right-content">
      <div className="dispatcher">
        <h1>Heat Map</h1>
        <div className="container-fluid">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="TRANSPORT" value="1" />
                  <Tab label="ORDER" value="2" />
                  <Tab label="SERVICE" value="3" />
                  <Tab label="DELIVERY" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="tabc">
                  <div className="container-fluid">
                    <div className="kerus d-flex">
                      <Button className="zzz">Toggle Heatmap </Button>
                      <Button className="zzz">Change gradient </Button>
                      <Button className="zzz">Change radius </Button>
                      <Button className="zzz">Change opacity </Button>
                    </div>
                    <div className="perus">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                        width="1000"
                        height="500"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Map of Kathmandu"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="tabc">
                  <div className="container-fluid">
                    <div className="kerus d-flex">
                      <Button className="zzz">Toggle Heatmap </Button>
                      <Button className="zzz">Change gradient </Button>
                      <Button className="zzz">Change radius </Button>
                      <Button className="zzz">Change opacity </Button>
                    </div>
                    <div className="perus">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                        width="1000"
                        height="500"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Map of Kathmandu"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="tabc">
                  <div className="container-fluid">
                    <div className="kerus d-flex">
                      <Button className="zzz">Toggle Heatmap </Button>
                      <Button className="zzz">Change gradient </Button>
                      <Button className="zzz">Change radius </Button>
                      <Button className="zzz">Change opacity </Button>
                    </div>
                    <div className="perus">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                        width="1000"
                        height="500"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Map of Kathmandu"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="4">
                <div className="tabc">
                  <div className="container-fluid">
                    <div className="kerus d-flex">
                      <Button className="zzz">Toggle Heatmap </Button>
                      <Button className="zzz">Change gradient </Button>
                      <Button className="zzz">Change radius </Button>
                      <Button className="zzz">Change opacity </Button>
                    </div>
                    <div className="perus">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23536.19423096028!2d85.30502643512737!3d27.709251206124037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1715757840253!5m2!1sen!2snp"
                        width="1000"
                        height="500"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Map of Kathmandu"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
