import React from "react";

function footer() {
  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <p>Copyrights 2024 All Right Reserved</p>
        </div>
      </div>
    </>
  );
}

export default footer;
