import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    language: "English",
    profilePicture: null,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a fetch delay of 1 second

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profilePicture: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="right-content">
      <div className="contentsss">
        <div className="container-fluid">
          <h1>Update Profile</h1>
          <div className="profileform">
            <Form onSubmit={handleSubmit}>
              <div className="formss  mt-4 p-2">
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                  />
                </Form.Group>
              </div>

              <div className="formss  mt-2 p-2">
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </Form.Group>
              </div>
              <div className="formss  mt-2 p-2">
                <Form.Group controlId="formLanguage">
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    as="select"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                  >
                    <option>English</option>
                    <option>Arabic</option>
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="formss  mt-2 p-2">
                <Form.Group controlId="formProfilePicture">
                  <Form.Label>Profile Picture</Form.Label>
                  <Form.Control
                    type="file"
                    name="profilePicture"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>

              <Button variant="primary mt-4 ms-1 p-2" type="submit">
                Update Profile
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
