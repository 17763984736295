import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { countries } from "countries-list";
import Container from "react-bootstrap/Container";

const countryOptions = Object.keys(countries).map((code) => ({
  value: code,
  label: countries[code].name,
}));

export default function Site() {
  const [value, setValue] = useState("1");
  const [file, setFile] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [switchCash, setSwitchCash] = useState(false);
  const [switchCard, setSwitchCard] = useState(false);
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeCurrency, setStripeCurrency] = useState("");
  const [timeoutUserSearch, setTimeoutUserSearch] = useState("");
  const [timeoutProviderAccept, setTimeoutProviderAccept] = useState("");
  const [creditRideLimit, setCreditRideLimit] = useState("");
  const [providerSearchRadius, setProviderSearchRadius] = useState("");
  const [switchManualAssigning, setSwitchManualAssigning] = useState(false);
  const [switchRideOTP, setSwitchRideOTP] = useState(false);
  const [bookingIDPrefix, setBookingIDPrefix] = useState("");
  const [serveOTP, setServeOTP] = useState(false);
  const [deliveryBoyResponseTime, setDeliveryBoyResponseTime] = useState("");
  const [orderOTP, setOrderOTP] = useState(false);
  const [searchRadius, setSearchRadius] = useState("");
  const [switchManualAssigningOrder, setSwitchManualAssigningOrder] =
    useState(false);
  const [switchDeliveryOTP, setSwitchDeliveryOTP] = useState(false);
  const [unitMeasurement, setUnitMeasurement] = useState("");
  const [switchReferral, setSwitchReferral] = useState(false);
  const [referralCount, setReferralCount] = useState("");
  const [referralAmount, setReferralAmount] = useState("");
  const [switch24HoursFormat, setSwitch24HoursFormat] = useState(false);
  const [
    providerMaxNegativeWalletBalance,
    setProviderMaxNegativeWalletBalance,
  ] = useState("");
  const [mobilePrefixCountryCode, setMobilePrefixCountryCode] = useState("");
  const [applicationModule, setApplicationModule] = useState({
    transport: false,
    service: false,
    order: false,
    delivery: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileSubmit = (event) => {
    event.preventDefault();
    if (file) {
      console.log("Selected file:", file.name);
    } else {
      console.log("No file selected.");
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleCountrySubmit = (event) => {
    event.preventDefault();
    if (selectedCountry) {
      console.log("Selected country:", selectedCountry.label);
    } else {
      console.log("No country selected.");
    }
  };

  const handleApplicationModuleChange = (key) => {
    setApplicationModule((prevModule) => ({
      ...prevModule,
      [key]: !prevModule[key],
    }));
  };
  return (
    <div className="right-content">
      <div className="site">
        <div className="container-fluid">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="General" value="1" sx={{ fontSize: "12px" }} />
                  <Tab
                    label="Comp. Profile Links"
                    value="2"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab
                    label="Social Link Config"
                    value="3"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab
                    label="Map & SMS Config"
                    value="4"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab
                    label="Mail Config"
                    value="5"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab
                    label="Push Notification"
                    value="6"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab
                    label="Payment Settings"
                    value="7"
                    sx={{ fontSize: "12px" }}
                  />
                  <Tab label="Transport" value="8" sx={{ fontSize: "12px" }} />
                  <Tab label="Service" value="9" sx={{ fontSize: "12px" }} />
                  <Tab label="Order" value="10" sx={{ fontSize: "12px" }} />
                  <Tab label="Delivery" value="11" sx={{ fontSize: "12px" }} />
                  <Tab label="Others" value="12" sx={{ fontSize: "12px" }} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="general">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Site Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Kantipur Ride"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-6">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group controlId="formFile">
                            <Form.Label>Site Icon</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group controlId="formFile">
                            <Form.Label>Site Logo</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formTel">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                              type="tel"
                              placeholder="Contact no."
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form.Group controlId="formEmail">
                          <Form.Label>Contact Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="info@kantipurride.com"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formTel">
                            <Form.Label>SOS Number</Form.Label>
                            <Form.Control type="tel" placeholder="100" />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText">
                            <Form.Label>Copyright Content</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="&copy; Copyrights 2024 All Rights Reserved"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form onSubmit={handleCountrySubmit}>
                          <Form.Group controlId="formCountrySelect">
                            <Form.Label>Select Country</Form.Label>
                            <Select
                              options={countryOptions}
                              value={selectedCountry}
                              onChange={handleCountryChange}
                              placeholder="Select a country"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form disabled>
                          <Form.Group controlId="formText">
                            <Form.Label>Unit Measurement</Form.Label>
                            <Form.Control type="text" placeholder="Kms" />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-6">
                        <Button>Submit</Button>
                      </div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="general">
                  <div className="container-fluid">
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Playstore User link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Playstore User link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Playstore Provider link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Playstore Provider link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText">
                            <Form.Label>Appstore User link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Appstore User link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText">
                            <Form.Label>Playstore Provider link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Playstore Provider link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText">
                            <Form.Label>Facebook link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Facebook link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-6">
                        <Form>
                          <Form.Group controlId="formText">
                            <Form.Label>Twitter link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Twitter link"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-6">
                        <Button>Submit</Button>
                      </div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="general">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Facebook App ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Facebook App ID"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Facebook App Secret</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Facebook App Secret"
                        />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Google Client Id</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Google Client Id"
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <Button className="ms-3 mt-5">Submit</Button>
                </div>
              </TabPanel>
              <TabPanel value="4">
                <div className="general">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Browser Map Key</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="AIzaSyA5zH69h2-DNes4VvLDIYQKWiHzgo0Ve6Y"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Server Map Key</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="AIzaSyA5zH69h2-DNes4VvLDIYQKWiHzgo0Ve6Y"
                        />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Android Map Key</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="AIzaSyA5zH69h2-DNes4VvLDIYQKWiHzgo0Ve6Y"
                        />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>IOS Map Key</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="AIzaSyA5zH69h2-DNes4VvLDIYQKWiHzgo0Ve6Y"
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <Button className="ms-3 mt-5">Submit</Button>
                </div>
              </TabPanel>
              <TabPanel value="5">
                <div className="geeneral">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Host</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="smtp.gmail.com"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Port</Form.Label>
                        <Form.Control type="text" placeholder="587" />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Username" />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="text" placeholder="Password" />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>From Address</Form.Label>
                        <Form.Control type="text" placeholder="admin@gox.com" />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>From Name</Form.Label>
                        <Form.Control type="text" placeholder="goX" />
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group controlId="formText" className="mt-3">
                        <Form.Label>Encryption</Form.Label>
                        <Form.Control type="text" placeholder="tls" />
                      </Form.Group>
                    </Form>
                  </div>
                  <Button className="ms-3 mt-5">Submit</Button>
                </div>
              </TabPanel>
              <TabPanel value="6">
                <div className="geeeneral">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>IOS Push Environment</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Development"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group controlId="formFile">
                            <Form.Label>IOS Push User Pem</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12 mt-3">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group controlId="formFile">
                            <Form.Label>IOS Push Provider Pem</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12 mt-3">
                        <Form onSubmit={handleFileSubmit}>
                          <Form.Group controlId="formFile">
                            <Form.Label>IOS Push Shop Pem</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <Form>
                          <Form.Group controlId="formTel">
                            <Form.Label>IOS Push Password</Form.Label>
                            <Form.Control type="tel" placeholder="123456" />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12 mt-3">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>USER IOS Bundle ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="com.quickituser.app"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12 mt-3">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>Shop IOS Bundle ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="com.quickitapp.shop"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-12 mt-3">
                        <Form>
                          <Form.Group controlId="formText" className="mt-3">
                            <Form.Label>USER IOS Bundle ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="AAAAT7GgTyc:APA91bE2hMU1eY6oXmikUodBjT4XStXdUHwplKTBwfrepkFGu2eJbdcrOlAyt8rya9uzwHfynJQPJHKEZErNrLI33IYL0Q7okyVfoPIVi1F4AukdupmvJPOim7RtRqhKpJMnYa8lzugQ"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="7">
                <div className="general">
                  <div className="container-fluid">
                    <Form>
                      <Form.Check
                        type="switch"
                        id="switchCash"
                        label="Cash"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                      <Form.Check
                        className="mt-3"
                        type="switch"
                        id="switchCard"
                        label="Card"
                        checked={switchCard}
                        onChange={() => setSwitchCard(!switchCard)}
                      />
                      <Form.Group controlId="stripeSecretKey" className="mt-3">
                        <Form.Label>Stripe Secret Key</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Stripe Secret Key"
                          value={stripeSecretKey}
                          onChange={(e) => setStripeSecretKey(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="stripePublishableKey"
                        className="mt-3"
                      >
                        <Form.Label>Stripe Publishable Key</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Stripe Publishable Key"
                          value={stripePublishableKey}
                          onChange={(e) =>
                            setStripePublishableKey(e.target.value)
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="stripeCurrency" className="mt-3">
                        <Form.Label>Stripe Currency</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Stripe Currency"
                          value={stripeCurrency}
                          onChange={(e) => setStripeCurrency(e.target.value)}
                        />
                      </Form.Group>
                      <Button className="mt-4">Submit</Button>
                    </Form>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="8">
                <div className="geeneral p-2">
                  <Form>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>User Searching Timeout(secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="250"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Accept Timeout(secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="60"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Credit Ride Limit</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Search Radius(kms)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="200"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Manual Assigning"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCard"
                      label="Ride OTP"
                      checked={switchCard}
                      onChange={() => setSwitchCard(!switchCard)}
                    />
                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>Booking ID Prefix</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="TRNS"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <Button className="mt-4">Submit</Button>
                  </Form>
                </div>
              </TabPanel>
              <TabPanel value="9">
                <div className="general p-2">
                  <Form>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Accept Timeout(secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="60"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Search Radius(kms)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="200"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Serve Otp"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>Booking ID Prefix</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="TRNS"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <Button className="mt-4">Submit</Button>
                  </Form>
                </div>
              </TabPanel>
              <TabPanel value="10">
                <div className="general p-2">
                  <Form>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Search Radius</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="200"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Delivery Boy Response Time(Secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="60"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Manual Assigning"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Order OTP"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>Booking ID Prefix</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="TRNS"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>New Product Timeline(In Days)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="15"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <Button className="mt-4">Submit</Button>
                  </Form>
                </div>
              </TabPanel>
              <TabPanel value="11">
                <div className="geeneral p-2">
                  <Form>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>User Searching Timeout(Secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="200"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Accept Timeout(Secs)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="60"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Search Radius</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="200"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Unit Measurement</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="kms"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Manual Assigning"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Check
                      className="mt-3"
                      type="switch"
                      id="switchCash"
                      label="Delivery OTP"
                      checked={switchCash}
                      onChange={() => setSwitchCash(!switchCash)}
                    />
                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>Booking ID Prefix</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="TRNS"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <Button className="mt-4">Submit</Button>
                  </Form>
                </div>
              </TabPanel>
              <TabPanel value="12">
                <div className="geeneral p-2">
                  <Form>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>DB Backup</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Common"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                      <Button className="mt-2">DB Backup Download</Button>
                      <Form.Check
                        className="mt-3"
                        type="switch"
                        id="switchCash"
                        label="Referrel"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Referrel count</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="5"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Referrel amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="50.00"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                      <Form.Check
                        className="mt-3"
                        type="switch"
                        id="switchCash"
                        label="24 Hours Format"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                    </Form.Group>
                    <Form.Group controlId="stripeSecretKey" className="mt-3">
                      <Form.Label>Provider Max -ve Wallet Balance</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="-500"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group controlId="stripeCurrency" className="mt-3">
                      <Form.Label>Mobile Prefix Country Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="NP"
                        value={stripeCurrency}
                        onChange={(e) => setStripeCurrency(e.target.value)}
                      />
                    </Form.Group>
                    <div className="Application d-flex">
                      <Form.Check
                        className="mt-3"
                        type="switch"
                        id="switchCash"
                        label="TRANSPORT"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                      <Form.Check
                        className="mt-3 ms-4"
                        type="switch"
                        id="switchCash"
                        label="SERVICE"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                      <Form.Check
                        className="mt-3 ms-4"
                        type="switch"
                        id="switchCash"
                        label="ORDER"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                      <Form.Check
                        className="mt-3 ms-4"
                        type="switch"
                        id="switchCash"
                        label="DELIVERY"
                        checked={switchCash}
                        onChange={() => setSwitchCash(!switchCash)}
                      />
                    </div>

                    <Button className="mt-4">Submit</Button>
                  </Form>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
